import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../TableComponents'
import { Laptop } from '@mui/icons-material'
import dayjs from 'dayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import { useAlert } from 'react-alert'
import CreateLaptopForm from '../Admin/AdminCreateForms/CreateLaptopForm'
import { BASE_URL } from '../../service/apiService'
export default function LaptopAssetAdminTable () {
  const apiRef = useGridApiRef()
  const itemName = 'laptopasset'
  const itemID = 'laptopID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const alert = useAlert()
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const handleEditCellChangeCommitted = params => {
    const isValid =
      params.props.value &&
      (typeof params.props.value === 'string'
        ? params.props.value.trim().length > 0
        : true)
    if (!isValid) {
      alert.error('Cannot be empty')
      console.log(params)
    }
    return { ...params.props, error: !isValid }
  }

  const columns = [
    {
      field: 'inUse',
      headerName: 'In Use',
      type: 'boolean',
      width: 100,
      editable: true
    },
    {
      field: 'laptopAssetID',
      headerName: 'Laptop Asset ID*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'brand',
      headerName: 'Brand*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'modelName',
      headerName: 'Model Name*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'modelNo',
      headerName: 'Model No',
      width: 200,
      editable: true
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      width: 200,
      type: 'date',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      },
      editable: true
    },
    {
      field: 'teamIDFk',
      headerName: 'Team',
      width: 200
    },
    {
      field: 'employeeIDFk',
      headerName: 'Employee Assigned',
      width: 200,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Employee'}
          value={params.value}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          fetchData={fetchShortUserInfo}
          extra={['name', 'lname']}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      ),
      editable: true
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 200
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 200
    },
    {
      field: 'imeiCode',
      headerName: 'IMEI Code',
      width: 200,
      editable: true
      // preProcessEditCellProps: params => {
      //   handleEditCellChangeCommitted(params)
      // }
    },
    {
      field: 'serialNo',
      headerName: 'Serial Number',
      width: 200,
      editable: true
    },

    {
      field: 'processor',
      headerName: 'Processor*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'baseboardSerialNo',
      headerName: 'Baseboard Serial No',
      width: 200,
      editable: true
      // preProcessEditCellProps: params => {
      //   handleEditCellChangeCommitted(params)
      // }
    },
    {
      field: 'baseboardVersion',
      headerName: 'Baseboard Version',
      width: 200,
      editable: true
      // preProcessEditCellProps: params => {
      //   handleEditCellChangeCommitted(params)
      // }
    },
    {
      field: 'productID',
      headerName: 'Product ID',
      width: 200,
      editable: true
    },
    {
      field: 'ram',
      headerName: 'RAM',
      width: 200,
      editable: true
      // preProcessEditCellProps: params => {
      //   handleEditCellChangeCommitted(params)
      // }
    },
    {
      field: 'osName',
      headerName: 'OS Name*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },

    {
      field: 'batteries',
      headerName: 'Batteries',
      width: 200,
      editable: true
      // preProcessEditCellProps: params => {
      //   handleEditCellChangeCommitted(params)
      // }
    },
    {
      field: 'storage',
      headerName: 'Storage',
      width: 200,
      editable: true
    },
    {
      field: 'diskDrives',
      headerName: 'Disk Drives',
      width: 200,
      editable: true
    },
    {
      field: 'screenSize',
      headerName: 'Screen Size',
      width: 200,
      editable: true
    },
    {
      field: 'charlesID',
      headerName: 'Charles ID',
      width: 200,

      editable: true
    },
    {
      field: 'charlesKey',
      headerName: 'Charles Key',
      width: 200,

      editable: true
    },
    {
      field: 'msofficeKey',
      headerName: 'MS Office Key',
      width: 200,

      editable: true
    },
    {
      field: 'msofficeUsername',
      headerName: 'MS Office Username',
      width: 200,

      editable: true
    },
    {
      field: 'msofficePassword',
      headerName: 'MS Office Password',
      width: 200,

      editable: true
    },
    {
      field: 'warranty',
      headerName: 'Warranty*',
      width: 200,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      },
      editable: true
    },
    {
      field: 'accessories',
      headerName: 'Accessories',
      width: 200,
      editable: true
    },

    {
      field: 'additionalItems',
      headerName: 'Additional Items',
      width: 200,

      editable: true
    },
    {
      field: 'otherDetails',
      headerName: 'Other Details',
      width: 200,
      editable: true
    }
  ]

  //  function func()
  //  {
  //   window.open("http://localhost:8082/dummyapi/redirect",null,"width=500px height=500px")
  //  }

  return (
    <>
      <div className='div-paperStyle2'>
        <div className='div-spaceToSides'>
          <>
            <Typography variant='h4'>
              <Laptop />
              Laptop Asset
            </Typography>
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen(true)}
            >
              +Add new laptop
            </Button>
          </>
        </div>

        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
        {/* <div  onClick={()=>func()}>
          AWEMS
          
        </div> */}
      </div>
      <CreateLaptopForm
        open={formOpen}
        handleClose={handleClose}
        setRefreshTable={setRefreshTable}
      />
    </>
  )
}
