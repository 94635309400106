import { Tabs, Tab, Typography, styled, Grid, Divider } from '@mui/material'
import React, { useState } from 'react'
import ShiftsTable from '../components/Admin/AdminControlTables.js/ShiftsTable'
import TeamTable from '../components/Admin/AdminControlTables.js/TeamTable'
import ProjectTable from '../components/Admin/AdminControlTables.js/ProjectTable'
import LocationTable from '../components/Admin/AdminControlTables.js/LocationTable'
import DepartmentTable from '../components/Admin/AdminControlTables.js/DepartmentTable'
import DesignationTable from '../components/Admin/AdminControlTables.js/DesignationTable'
import EmployeeTypeTable from '../components/Admin/AdminControlTables.js/EmployeeTypeTable'
import LeaveTypeTable from '../components/Admin/AdminControlTables.js/LeaveTypeTable'
import ThresholdsTable from '../components/Admin/AdminControlTables.js/ThresholdsTable'
import HolidayListTable from '../components/Admin/AdminControlTables.js/HolidayListTable'
import RolesTable from '../components/Admin/AdminControlTables.js/RolesTable'
import LeaveReasonTable from '../components/Admin/AdminControlTables.js/LeaveReasonTable'
import { CustomTab } from '../stylings/customtabstyle'
import { useAuthUser } from 'react-auth-kit'
import AlertMessagesTable from '../components/Admin/AdminControlTables.js/AlertMessages'
import { pageStyle } from '../stylings/pageStyle'
import DeviceDamageTable from '../components/Admin/AdminControlTables.js/DeviceDamageTable'
import ResetPasswords from '../components/Admin/AdminControlTables.js/ResetPasswords'
export default function AdminControls () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps ({ index }) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  function CustomTabPanel ({ children, value, index, ...other }) {
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }
  return user().modules.find(m => m.name === 'ADMINCONTROLS_MODULE') ? (
    <Grid
      container
      columns={20}
      style={pageStyle}
      gridRow={1}
      className='containerStyleLogin'
    >
      <Grid item xs={3} md={3} lg={3} xl={3}>
        <Tabs
          indicatorColor='secondary'
          value={value}
          orientation='vertical'
          variant='scrollable'
          onChange={handleChange}
          sx={{ backgroundColor: 'aliceblue' }}
        >
          <CustomTab
            label={<Typography variant='h6'>Shift</Typography>}
            value={0}
            {...a11yProps({ index: 0 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Team</Typography>}
            value={1}
            {...a11yProps({ index: 1 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Project</Typography>}
            value={2}
            {...a11yProps({ index: 2 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Location</Typography>}
            value={3}
            {...a11yProps({ index: 3 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Department</Typography>}
            value={4}
            {...a11yProps({ index: 4 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Designation</Typography>}
            value={5}
            {...a11yProps({ index: 5 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Employee Type</Typography>}
            value={6}
            {...a11yProps({ index: 6 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Leave Type</Typography>}
            value={7}
            {...a11yProps({ index: 7 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Schedule Thresholds</Typography>}
            value={8}
            {...a11yProps({ index: 8 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Holiday List</Typography>}
            value={9}
            {...a11yProps({ index: 9 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Roles</Typography>}
            value={10}
            {...a11yProps({ index: 10 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Leave Reason</Typography>}
            value={11}
            {...a11yProps({ index: 11 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Alerts</Typography>}
            value={12}
            {...a11yProps({ index: 12 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Device Damages</Typography>}
            value={13}
            {...a11yProps({ index: 13 })}
          />
          <CustomTab
            label={<Typography variant='h6'>Reset Password</Typography>}
            value={14}
            {...a11yProps({ index: 13 })}
          />
        </Tabs>
      </Grid>
      <Grid item xs={17} md={17} lg={17} xl={17}>
        <CustomTabPanel value={value} index={0} children={<ShiftsTable />} />
        <CustomTabPanel value={value} index={1} children={<TeamTable />} />
        <CustomTabPanel value={value} index={2} children={<ProjectTable />} />
        <CustomTabPanel value={value} index={3} children={<LocationTable />} />
        <CustomTabPanel
          value={value}
          index={4}
          children={<DepartmentTable />}
        />
        <CustomTabPanel
          value={value}
          index={5}
          children={<DesignationTable />}
        />
        <CustomTabPanel
          value={value}
          index={6}
          children={<EmployeeTypeTable />}
        />
        <CustomTabPanel value={value} index={7} children={<LeaveTypeTable />} />
        <CustomTabPanel
          value={value}
          index={8}
          children={<ThresholdsTable />}
        />
        <CustomTabPanel
          value={value}
          index={9}
          children={<HolidayListTable />}
        />
        <CustomTabPanel value={value} index={10} children={<RolesTable />} />
        <CustomTabPanel
          value={value}
          index={11}
          children={<LeaveReasonTable />}
        />
        <CustomTabPanel
          value={value}
          index={12}
          children={<AlertMessagesTable />}
        />
        <CustomTabPanel
          value={value}
          index={13}
          children={<DeviceDamageTable />}
        />
        <CustomTabPanel
          value={value}
          index={14}
          children={<ResetPasswords/>}
        />
      </Grid>
    </Grid>
  ) : null
}
