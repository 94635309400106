import React, { useEffect, useState } from 'react'
import { TextField, Button } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import {
  fetchAttendaceRequestsForManager,
  arbitrateAttendaceRequests
} from '../../service/attendanceService'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { dataGridStyles } from '../../stylings/datagridStyles'

export default function AttendanceRequest () {
  const [AttendanceRequests, setAttendanceRequest] = useState([])
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const apiRef = useGridApiRef()
  const [progress, setProgress] = useState(false)

  async function fetchData () {
    setProgress(true)
    try {
      const response = await fetchAttendaceRequestsForManager(
        user().team,
        user().employeeID
      )
      const data = await response.json()
      const newRows = data.map(item => ({
        attendanceID: item.attendance.attendanceID,
        approved: item.attendance.approved,
        comments: item.attendance.comments,
        date: dayjs(item.attendance.date).toDate(),
        employee_id: item.attendance.employee_id,
        ipv4Address: item.attendance.ipv4Address,
        latitude: item.attendance.latitude,
        leave: item.attendance.leave,
        longitude: item.attendance.longitude,
        managerRemark: item.attendance.managerRemark,
        requestType: item.attendance.requestType,
        shiftType: item.attendance.shiftType,
        time: item.attendance.time,
        workedOn: item.attendance.workedOn,
        name: item.name,
        lname: item.lname,
        requestDate: dayjs(item.attendance.requestDate).toDate()
      }))
      setAttendanceRequest(newRows)
    } catch (error) {
      console.error(error)
    } finally {
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) fetchData()
  }, [user(), isAuthenticated()])

  const renderRadioButtons = params => {
    const row = params.row
    return (
      <div className='div-spaceToSides'>
        <Button
          value={'true'}
          onClick={e => {
            handleRadioChange(e, row.attendanceID)
            handleSubmit(row)
          }}
          color='secondary'
          variant='outlined'
          sx={{margin:'5px'}}
        >
          Approve
        </Button>
        <Button
          value={'false'}
          onClick={e => {
            handleRadioChange(e, row.attendanceID)
            handleSubmit(row)
          }}
          color='error'
          variant='outlined'
          sx={{margin:'5px'}}
        >
          Deny
        </Button>
      </div>
    )
  }

  const renderManagerRemarksCell = params => {
    const row = params.row
    return (
      <TextField
        fullWidth
        type='text'
        value={row.managerRemark}
        onChange={event => handleManagerRemarksChange(event, row.attendanceID)}
      ></TextField>
    )
  }
  const columns = [
    {
      field: 'employee_id',
      headerName: 'Employee ID',
      width: 150
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: 150
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'requestDate',
      headerName: 'Request Sent On',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'comments',
      headerName: 'Reason for Request',
      width: 200,
      sortable: false,
      filterable: false
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 200,
      minWidth:100,
      renderCell: renderRadioButtons
    },
    {
      field: 'managerRemark',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: renderManagerRemarksCell
    }
  ]
  const handleManagerRemarksChange = (event, attendanceID) => {
    const index = AttendanceRequests.findIndex(
      request => request.attendanceID === attendanceID
    )

    if (index !== -1) {
      const updatedRequests = [...AttendanceRequests]
      updatedRequests[index].managerRemark = event.target.value
      setAttendanceRequest(updatedRequests)
    }
  }
  const handleRadioChange = (event, attendanceID) => {
    const updatedRequests = [...AttendanceRequests]
    const updatedRequest = updatedRequests.find(
      request => request.attendanceID === attendanceID
    )
    updatedRequest.approved = event.target.value
    setAttendanceRequest(updatedRequests)
  }

  const handleApprovalOfAll = approval => {
    const updatedRequests = rowSelectionModel.map(attendanceID => {
      return AttendanceRequests.find(att => att.attendanceID === attendanceID)
    })
    updatedRequests.forEach(req => (req.approved = approval))
    setAttendanceRequest(
      AttendanceRequests.filter(a => !updatedRequests.includes(a))
    )
    handleSubmit()
  }
  const handleSubmit = async aReq => {
    try {
      setProgress(true)
      if (aReq) {
        const res = await arbitrateAttendaceRequests(aReq.approved, aReq)
      } else {
        for (const attendanceID of rowSelectionModel) {
          const selectedRequest = AttendanceRequests.find(
            att => att.attendanceID === attendanceID
          )
          await arbitrateAttendaceRequests(
            selectedRequest.approved,
            selectedRequest
          )
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      fetchData()
      setProgress(false)
      setRowSelectionModel([])
    }
  }



  return (
    <>
      <DataGrid
        disableRowSelectionOnClick
        checkboxSelection
        autoHeight
        loading={progress}
        rows={AttendanceRequests}
        columns={columns}
        getRowId={row => row.attendanceID}
        apiRef={apiRef}
        sx={dataGridStyles}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={newRowSelectionModel =>
          setRowSelectionModel(newRowSelectionModel)
        }
        showCellVerticalBorder
        initialState={{
          sorting: {
            sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
          },
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5
            }
          }
        }}
        pageSizeOptions={[5, 10, 15, 20, 100]}
      />
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => handleApprovalOfAll(true)}
        sx={{ margin: '1%' }}
      >
        Approve All Selected
      </Button>
      <Button
        variant='outlined'
        color='error'
        onClick={() => handleApprovalOfAll(false)}
      >
        Deny All Selected
      </Button>
    </>
  )
}
