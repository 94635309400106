import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const resetPassword = async (employeeID, password) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/user/resetPassword/' + employeeID,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: password
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const forgotPassword = async email => {
  try {
    const response = await fetch(BASE_URL + '/auth/forgotpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: email
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// export const resetEmail = async (employeeID,email) => {
//     try {
//       const authToken = Cookies.get('_auth');
//       const response = await fetch(BASE_URL + '/user/resetEmail/'+employeeID, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${authToken}`
//         },
//         body: JSON.stringify(email)
//       });

//     } catch (error) {
//       console.error(error);
//       throw error;
//     }
// }

export const resetOtherDetails = async (employeeID, userdetails) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/user/resetDetails/' + employeeID,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(userdetails)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const profilePictureOfUser = async (employeeID, auth) => {
  try {
    const response = await fetch(
      BASE_URL + '/user/profilepicture/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth
        }
      }
    )
    return response.blob().then(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(blob)
      })
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const uploadProfilePicture = async (employeeID, userpicture) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/user/uploadProfilePicture?employeeID=' + employeeID,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        body: userpicture
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteProfilePicture = async employeeID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/user/removeProfilePicture?employeeID=' + employeeID,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
